import axios from "axios";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Loading Plan service
 * Provides UI business logic for loading plan
 */
export class LoadingPlanService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(includes = ""): Promise<IServiceResponse> {
    try {
      const inclStr = includes != "" ? `/?includes=${includes}` : "";
      const result = await axios.get(`${this.API_URL}/LoadingPlan${inclStr}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(loadingPlan: ILoadingPlan): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/LoadingPlan`,
        loadingPlan,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(
        `${this.API_URL}/LoadingPlan/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async update(loadingPlan: ILoadingPlan): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/LoadingPlan/Update`,
        loadingPlan,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
}
