import axios from "axios";
import { IProject } from "@/types/Project";
/*
 *Project service
 * Provides UI business logic for project
 */
export class ProjectService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IProject[]> {
    //console.log("getProject:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/Project`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
}
