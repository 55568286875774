
import { Component, Vue } from "vue-property-decorator";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { Defaults } from "@/helpers/Defaults";
const LoadingPlanProps = Vue.extend({
  props: {
    transporters: [],
    commodities: [],
    warehouses: [],
    districts: [],
    activities: [],
    loadingPlan: Object,
    modal: Object,
  },
});
@Component({
  name: "LoadingPlanModal",
  components: {},
})
export default class LoadingPlanModal extends LoadingPlanProps {
  show = false;
  project = "Lean Season Response";
  transporterState = null;
  monthState = null;
  commodityState = null;
  quantityState = null;
  warehouseState = null;
  districtState = null;
  startDateState = null;
  endDateState = null;
  nameState = null;
  name = "";
  //defaults = new Defaults();
  //loadingPlan: ILoadingPlan = this.defaults.loadingPlan;

  save(): void {
    let loadingPlan = this.loadingPlan;
    loadingPlan.commodityId = loadingPlan.commodity.id;
    loadingPlan.warehouseId = loadingPlan.warehouse.id;
    loadingPlan.transporterId = loadingPlan.transporter.id;
    loadingPlan.districtId = loadingPlan.district.id;
    loadingPlan.activityId = loadingPlan.activity.id;
    this.$emit("save:loading-plan", loadingPlan);
  }
}
