
import { Component, Vue } from "vue-property-decorator";
import { Defaults } from "@/helpers/Defaults";
import { IRequestedCommodity } from "@/types/RequestedCommodity";
import { PropType } from "vue";
import { ICommodity } from "@/types/Commodity";

const DisasterProps = Vue.extend({
  props: {
    projects: [],
    commodities: [] as PropType<ICommodity[]>,
    activities: [],
    districts: [],
    modal: Object,
  },
});
@Component({
  name: "DisasterModal",
  components: {},
})
export default class DisasterModal extends DisasterProps {
  defaults = new Defaults();
  requisition = this.defaults.requisition;
  reliefItems: IRequestedCommodity[] = [];
  commodity: ICommodity = this.defaults.commodity;
  oldCommodity: ICommodity = this.defaults.commodity;
  selectedCommodities: number[] = [];
  project = "Emergency Response";
  save(): void {
    this.requisition.requestedCommodities = this.reliefItems;
    this.$emit("save:requisition", this.requisition);
  }
  removeReliefItem(item: IRequestedCommodity, index: number): void {
    this.selectedCommodities = this.selectedCommodities.filter(
      (id) => id != item.commodityId
    );
    this.reliefItems.splice(index, 1);
  }
}
