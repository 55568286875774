import axios from "axios";
import { IActivity } from "@/types/Activity";
/*
 *Activity service
 * Provides UI business logic for activity
 */
export class ActivityService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IActivity[]> {
    //console.log("getActivity:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/Activity`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
}
