import axios from "axios";
import { IRequisition } from "@/types/Requisition";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Requisition service
 * Provides UI business logic for requisition
 */
export class RequisitionService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(
    includes = '["Instruction","Activity","Activity.Project","Requester","RequestedCommodities"]'
  ): Promise<IServiceResponse> {
    //console.log("getRequisition:", this.API_URL);
    const inclStr = includes != "" ? `/?includes=${includes}` : "";
    const result: any = await axios.get(
      `${this.API_URL}/Requisition${inclStr}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      }
    );
    return result.data;
  }
  public async create(requisition: IRequisition): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/Requisition`,
        requisition,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
